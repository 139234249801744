/*************/
/**Theme.css**/
/**Author:Gowtham**/

/** Globel **/
/** Button **/
/****** Components Start ******/

/* Nav */
/* Tabs  */

/****** Components End ******/
/****** Pages Start ******/

/* Home Page */
/* Request Demo Page */
/* Team Page */
/* School Tab 1 page */
/* Parent Tab 1 page */
/* School Tab 2 page */
/* Parent Tab 2 page */
/* School Tab 3 page */
/* Parent Tab 3 page */
/* School Tab 4 page */
/* Not Found Page And Loader Page */

/****** Pages END ******/

@media (max-width: 1200px) {
    /* Tabs Styles Start*/
  
    .yow-tab-wrapper {
        width: 25%;
    }

    .yow-next {
        position: absolute;
        bottom: 90px;

    }

    .yow-next-icon {
        position: relative;

    }

    .book-width-wrapper {
        position: relative;
        width: 90%;
        margin: auto;
    }

    .yow-next-icon-library {
        position: absolute;
        bottom: 90px;
        right: 0px;

    }


    /* Tabs Styles End*/
}

/* tablet view Style start*/

@media (max-width: 992px) {

    /****** Components Start ******/

    /* Nav Style Start */

    .yow-nav-link {
        margin-right: 0px;
        margin-top: 10px;
    }

    .navbar-toggler:focus {
        box-shadow: none !important;
    }

    /* Nav logo start */

    .yow-mob-nav-logo {
        width: 17%;
    }

    .yow-mob-nav-logo img {
        width: 100%;
    }
    .nav-item{
        margin-left: 10px;
     }

    /* Nav logo end */

    /* Nav Style End */


    /* Library shelf start */
    .yow-self {
        width: 85%;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
    }


    .yow-book-list {
        width: 70%;
        margin: auto;
        position: relative;
        z-index: 1;
    }

    /* Library shelf end */



    /****** Components End ******/


    /****** Pages Start ******/
    /* Request To Demo Page Style Start */


    .yow-book-shape .order-0 {
        margin-bottom: 100px;
    }

    /* Request To Demo Page Style Start */

    /* Not Found Page And Loader Page Style Start */

    .yow-notfound-image {
        width: 60%;
    }

    .yow-page-wrapper .logo img {
        width: 40%;
    }

    /* Not Found Page And Loader Page Style End */

    /* School Tab 2 And Parent Tab 2 Style Start */

    /* Flip Arrow Style Start */

    .yow-flip-arrow {
        width: 8%;
        position: absolute;
        bottom: 18%;
        right: 10%;
    }

    /*Flip Arrow Style End */

    /* School Tab 2 And Parent Tab 2 Style Start */

    /* Team Page Style Start */

    .yow-top-space {
        margin-top: 50px;
    }

    .personal_info-left {
        padding-left: 0px;
        float: none;
    }

    .space {
        padding-left: 0px;
        padding-right: 0px;
    }

    .team .image_size {
        width: 70%;
    }

    .form-wrapper input[type="number"]::-webkit-outer-spin-button,
    .form-wrapper input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .form-wrapper input[type="number"] {
        -moz-appearance: none;
    }

    

    /* Team Page Style End */

    /* Parent Tab 1 And School Tab 3 Style Start */

    .parents-content {
        padding-bottom: 40px;
    }

    .bottomSpace {
        padding-bottom: 40px;
    }

    .parents-one .title-wrapper {
        margin: auto;
        /* padding-bottom: 50px; */
    }

    .left_image {
        margin: auto;
        padding-left: 30px;
    }

    .right_image {
        margin-bottom: 80px;
    }

    /* Parent Tab 1 And School Tab 3 Style End */

    /* Library Page Style start */

    .yow-library-bg {
        width: 100%;
        height: 100vh;
        background: rgb(60, 48, 119);
        background: linear-gradient(180deg, rgba(60, 48, 119, 1) 30%, rgba(251, 236, 83, 1) 20%);
    }

    .yow-library-left-content {
        width: 100%;
        height: auto;
        text-align: center;
        font-size: var(--font-size-18);
        margin: 0;
    }

    .yow-library-heading {
        margin-top: 70px;
    }

    .yow-library-logo {
        width: 170px;
        margin-top: 10px;
        text-align: center;
        margin: 0 auto;
    }

    .yow-library-content {
        margin: 10px auto;
        padding-bottom: 10px;
    }

    /* Library Page Style end */

    /* School Tab 4 And Parent Tab 3 Style Start */

    .yow-subheading-wrapper {
        margin-top: 0px;
    }

    .yow-desktop-view {
        display: none;
    }

    .yow-mobile-view {
        display: block;
    }

    .yow-bg-light-yellow-card,
    .yow-bg-medium-yellow-card,
    .yow-bg-yellow-card {
        height: 150px;
        width: 100%;
    }

    .yow-space-top {
        padding-top: 45px;
    }

    /* next-icon style page start */
    .yow-next {
        position: absolute;
        bottom: 170px;

    }

    .yow-next-icon {
        position: relative;

    }

    .book-width-wrapper {
        position: relative;
        width: 90%;
        margin: auto;
    }

    .yow-next-icon-library {
        position: absolute;
        bottom: 160px;
        right: -45px;

    }

    /* next-icon style page end */

    /* School Tab 4 And Parent Tab 3 Style Start */

    /****** Pages Start ******/
}

/* Tablet View End*/

/* Mobile Landscape View Start*/

@media screen and (max-width: 768px) {

    /* General Start */

    .yow-book-shape {
        clip-path: polygon(1% 0%, 50% 1%, 99% 0%, 100% 100%, 0% 100%);
        background: #fff;
        padding: 100px 0px;
    }

    /* General End */

    /****** Components Start ******/

    /* Nav Logo Start */

    .yow-mob-nav-logo {
        width: 22%;
    }

    .yow-bird-logo img {
        width: 50% !important;
    }

    /* Nav Logo End */

    /* Tabs Style Start*/

    .yow-tabs {
        width: 100%;
        margin: auto;
    }

    .yow-tabs nav {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .yow-tab-wrapper {
        width: 80%;
        text-align: center;
        margin-bottom: 10px;
        font-size: 14px;
    }

    .yow-tab-wrapper a div {
        border-radius: 15px !important;
    }

    .yow-tabs {
        width: 100%;
        margin: auto;
    }

    .yow-first-tab {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .yow-last-tab {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    /* Tabs Styles End*/

    /* Title Style Start */

    .title-wrapper h2 {
        font-size: 18px;
    }

    /* Title Style End */

    /****** Components Start ******/

    /****** Pages Start ******/

    /* Home Banner Image Start */
    .yow-bird-logo img {
        width: 70%;
    }

    /* Logo Start*/
    .logo img {
        width: 70%;
    }

    /* Logo End*/

    /* Home Banner Image End */

    /* Home Bottom Button Start */
    .yow-btn-sm-center {
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }

    .yow-btn-right {
        text-align: center;
        margin-right: auto;
    }

    /* Home Bottom Button End */

    /* Request Demo Page Style Start */

    .yow-book-shape .order-0 {
        margin-bottom: 0px;
    }

    .yow-form-title {
        width: 100%;
    }

    .yow-form-input {
        padding: 10px 0px;
        width: 100%;
    }

    .form-wrapper {
        width: 100%;
        margin: auto;
    }

    /* Request Demo Page Style End */

    /* Not Found Page And Loader Page Style Start */
    .yow-notfound-image {
        width: 100%;
    }

    .yow-page-wrapper .logo img {
        width: 60%;
    }

    /* Not Found Page And Loader Page Style End */

    /* School Tab 2 And Parent Tab 2 Style Start */

    /* Flip Arrow Style Start */
    .yow-flip-arrow {
        width: 8%;
        position: absolute;
        bottom: 20%;
        right: 10%;
    }

    /*Flip Arrow Style End */

    /* School Tab 2 And Parent Tab 2 Style Start */

    /* Team Page Style Start */

    .yow-top-space {
        margin-top: 0px;
    }

    .personal_info-left {
        padding-left: 0px;
    }

    .team .image_size img {
        width: 100%;
        padding-bottom: 20px;
    }

    .team .image_size {
        width: 100%;
    }

    /* Team Page Style End */


    /* School Tab 1 Style Start */

    .title-wrapper {
        width: 100%;
    }

    .school-tab-one .title-wrapper {
        width: 100%;
    }

    .school-content {
        font-size: 16px;
        padding: 0px;
    }


    /* School Tab 1 Style End */

    /* Parent Tab 1 And School Tab 3  Style Start */

    .yow-book-shape {
        padding-top: 0px;
    }

    .parents-content {
        font-size: 16px;
        padding-top: 0px;
    }

    .parents-one img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 90%;
    }

    .parents-one .title-wrapper {
        width: 100%;
        margin-left: 0px;
        /* padding-bottom: 20px; */
    }

    .parents-one .left_image {
        padding-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
    }

    .parents-one .right_image {
        padding: 50px 0px;
        /* margin-bottom: 80px; */
        margin-right: auto;
    }

    .parents-one .px-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    /* Parent Tab 1 And School Tab 3  Style End */

    /* School Tab 4 And Parent Tab 4 Style Start */

    .yow-card {
        padding: 0px;
        width: 80%;
        margin: auto;
    }

    .yow-purple-border-bottom {
        border-radius: 15px;
    }

    /* School Tab 4 And Parent Tab 4 Style End */

    /* Library Page Style start */

    .yow-library-bg {
        width: 100%;
        height: 100vh;
        background: rgb(60, 48, 119);
        background: linear-gradient(180deg, rgba(60, 48, 119, 1) 35%, rgba(251, 236, 83, 1) 20%);
    }

    .yow-library-left-content {
        width: 100%;
        height: auto;
        font-size: var(--font-size-18);
    }

    .yow-library-heading {
        margin-top: 70px;
    }

    .yow-library-logo {
        width: 150px;
        margin-top: 20px;
    }

    .yow-library-content {
        margin: 10px auto;
        padding-bottom: 10px;

    }

    /* Library Page Style end */

    /* self component style start */
    .yow-self-book {
        height: 150px;
        width: 150px;
    }

    .yow-self {
        width: 85%;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
    }


    .yow-book-list {
        width: 70%;
        margin: auto;
        position: relative;
        z-index: 1;
    }

    .yow-book-self-wrapper {
        width: 100%;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    /* self component style end */

    /* Subscription page Style Start */

    .subscribe-form-wrapper {
        margin-left: auto;
    }

    /* Subscription page Style End */

    /* Team Page Start */
    .teamspace {
        margin: 30px 0 0;
    }

    /* Team Page End */
    .yow-space-top {
        padding-top: 30px;
    }
    .team_image {
        height: auto !important;
        object-fit: cover !important;
    }

    /* next-icon btn style start */

    .yow-next {
        position: absolute;
        bottom: 170px;

    }

    .yow-next-icon {
        position: relative;

    }

    .book-width-wrapper {
        position: relative;
        width: 90%;
        margin: auto;
    }

    .yow-next-icon-library {
        position: absolute;
        bottom: 160px;
        right: -30px;

    }


    /* next-icon btn style end */
    .home-margin{
        margin-top: 10px !important;
    }
    /****** Pages End ******/

}

/* Mobile Landscape View End*/


/* Mobile View Start*/

@media screen and (max-width: 576px) {


    /* Top space for all page - end */


    /****** Components Start ******/

    /* Nav Logo Start */

    .yow-mob-nav-logo {
        width: 33%;
    }
    .home-margin{
        margin-top: 10px !important;
    }

    /* Nav Logo End */

    /****** Components End ******/

    /****** Pages Start ******/

    /* School Tab 2 And Parent Tab 2 Style Start */

    /* Flip Arrow Style Start */

    .yow-flip-arrow {
        width: 10%;
        position: absolute;
        bottom: 28%;
        right: 10%;
    }

    /*Flip Arrow Style End */

    /* School Tab 2 And Parent Tab 2 Style Start */

    /* Subscription Page Start */
    .subscribe-form-wrapper {
        margin: auto;
    }

    /* Subscription Page End */
    .yow-subscribe {
        padding: 25px 0 0;
    }

    /* Library Page Style start */

    .yow-library-bg {
        width: 100%;
        height: auto;
        background: rgb(60, 48, 119);
        background: linear-gradient(180deg, rgba(60, 48, 119, 1) 22%, rgba(251, 236, 83, 1) 20%);
    }

    .yow-library-left-content {
        width: 100%;
        font-size: var(--font-size-18);

    }

    .yow-book-card .card {
        width: 60%;
        margin: 30px auto !important;
        cursor: default;
    }

    .yow-book-shape {
        background: #fff;
        padding: 10px 0;
    }


    .yow-library-heading {
        margin-top: 60px;
    }

    .yow-library-logo {
        width: 180px;
        margin-top: 20px;
    }

    .yow-library-content {
        margin: auto;
        padding-top: 20px;

    }

    .yow-self {
        display: none;
    }

    .yow-library-heading {
        margin-top: 80px;
    }

    .yow-self-book img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform: translate(0px, 0px);
    }

    .yow-self-book {
        margin: 50px 0;
    }



    /* Library Page Style end */

    .book-width {
        width: 100%;
    }

    .yow-next-icon-library {
        display: none;
    }

    .yow-next {
        display: none;

    }
    /* Team page style start */
    .team_image {
        height: auto !important;
        object-fit: cover !important;
    }
    .personal_info{
        text-align: center;
    }
    .personal_info img{
        width: 90%;
    }
    /* Team page style end */

    /****** Pages END ******/
}

/* Media query 375 for Iphone Se */

@media screen and (min-width: 375px) {
    .btn-space {
        padding-bottom: 20px;
    }
}

/* Mobile  View  End*/