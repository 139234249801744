

.yow-flip-image {
    width: 100%;
    height: 100%;
}

.yow-book {
    display: flex;
    justify-content: end;
}

.yow-cover {
    width: 250px;
    /* height: 400px; */
}

.yow-flip-book {
    width: 250px;
    /* height: 400px; */
    position: relative;
    perspective: 1500px;
}

.yow-flip {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: left;
    transform-style: preserve-3d;
    transform: rotateY(0deg);
    transition: .5s;
    color: #000;
}


.yow-flip-right-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fafafa;
    box-sizing: border-box;
    padding: 0 13px;
}

.yow-flip-left-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    transform: rotateY(180deg);
    backface-visibility: hidden;
    background-color: #000;
}

.next-btn {
    position: absolute;
    bottom: 13px;
    right: 13px;
    cursor: pointer;
    color: #000;
}

.back-btn {
    position: absolute;
    bottom: 13px;
    right: 13px;
    cursor: pointer;
    color: #fff;
}

#p1 {
    z-index: 3;
    transition: 1s;
}
#p2 {
    z-index: 2;
    transition: 1s;
}
#p3 {
    z-index: 1;
    transition: 1s;
}

#c1:checked ~ .yow-flip-book #p1 {
    transform: rotateY(-180deg);
    transition: 1s;
    z-index: 1;
}
#c2:checked ~ .yow-flip-book #p2 {
    transform: rotateY(-180deg);
    transition: 1s;
    z-index: 2;
}
#c3:checked ~ .yow-flip-book #p3 {
    transform: rotateY(-180deg);
    transition: 1s;
    z-index: 3;
}


.flipbook {
    background-color: goldenrod;
}

.flipbook img {
    height: 100%;
    width: 100%;
    object-fit: fill;
}

.demoPage1 {
    width: 300px;
    height: 300px;
    background-color: aqua;
}

.demoPage {
    /* height: 1000px; */
    /* top: 120px !important; */
    /* height: 600px !important; */
    /* width: 600px !important; */
    /* left: 0px; */
 
}

.demoPage img {
    height: 100%;
    width: 100%;
    object-fit: fill;
}