.yow-book-self-wrapper {
    width: 120% !important;
    margin-top: 0px;
    margin-Bottom: 30px;
}

.bookCover {
    border-radius: 10px;
    cursor: pointer;
    height: 90vh;
    box-shadow: rgb(17 17 26 / 71%) 0px 4px 16px, rgb(17 17 26 / 34%) 0px 8px 32px;
    transition: 2s ease;
    transform-origin: left;
    transform-style: preserve-3d;
    transform: rotateY(0deg);
    z-index: 1;
}

.bookCoverBack {
    position: absolute;
    border-radius: 10px;
    height: 90vh;
    z-index: 0;
    background-color: #fff;
}

.bookCoverBack img {
    opacity: 0;
    border-radius: 10px;
    cursor: pointer;
    height: 90vh;
}

.flippedBookCover {
    transform: rotateY(270deg);
}