body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'MacondoRegular';
  src: local('MacondoRegular'), url('./assets/fonts/Macondo-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'CormorantGaramondRegular';
  src: local('CormorantGaramondRegular'), url('./assets/fonts/CormorantGaramond-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'RalewayRegular';
  src: local('RalewayRegular'), url('./assets/fonts/Raleway-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Schoolbell';
  src: local('Schoolbell'), url('./assets/fonts/Schoolbell-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Livvic-Bold';
  src: local('Livvic-Bold'), url('./assets/fonts/Livvic-Bold.ttf') format('truetype');
}

/* 
@media (max-width: 768px) {
  body{
    display: none;
  }
} */
