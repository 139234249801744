/*************/
/**Theme.css**/
/**Author:Gowtham**/
/** root **/
/** Globel **/

/****** Components Start ******/

/* Button */
/* Nav */
/* Title */
/* Drop Down */
/* Tabs  */

/****** Components End ******/
/****** Pages Start ******/

/* Home  */
/* Request Demo */
/* Team */
/* School Tab 1 page */
/* Parent Tab 1 page */
/* School Tab 2 page */
/* Parent Tab 2 page */
/* School Tab 3 page */
/* Parent Tab 3 page */
/* School Tab 4 page */
/* library Page */
/* 404 Page And Loader Page*/

/****** Pages END ******/

/****** fonts imported  *******/
/* @import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Schoolbell&display=swap');

html,
body {
    font-family: "Schoolbell", cursive !important;
} */


:root {
    --primary-color: #3C3077;
    --secondary-color: #FCE967;
    --color-1: #FCFBFF;
    --color-2: #FDF5A9;
    --color-3: #E1DEF0;
    --color-4: #F0F0CF;
    --color-5: #FFFFF6;
    --color-6: #703F00;
    --color-7: #E7D5C0;
    --color-8: #F1E0AD;
    --color-9: #FEF9C6;
    --color-10: #FDF5A0;
    --color-11: #F8EC6E;
    --white-color: #FFFFFF;
    --black-color: #000000;
    --gray-color: #E4E4E4;
    --nav-link-siz: 22px;
    --yow-bold: 600;
    --yow-semi-bold: 500;
    --btn-radius-20: 20px;
    --font-size-18: 18px;
    --font-size-30: 30px;
    --font-size-16: 16px;
    --font-size-20: 20px;
    --font-size-22: 22px;
    --font-size-24: 24px;
    --font-size-25: 25px;
    --font-size-26: 26px;
    --font-size-28: 28px;
    --font-size-34: 34px;
    --line-height-22: 22px;
    --line-height-46: 46px;
    --font-weight-500: 500;
    --font-weight-600: 600;
}

/**Globel Style Start**/

a {
    color: var(--primary-color);
    text-decoration: none !important;
}
.yow-center{
    text-align: center;
}
/* Library Book */
.bg-yellow {
    background-color: var(--color-11);
}

.br-card {
    border-radius: 15px;
}

.book-info p {
    font-size: 13px;
}

/* Bg Purple Style Start */
.yow-bg-purple {
    background-color: var(--primary-color);
}

/* Bg Purple Style End */

/* Bg Purple Yellow Style Start */
.yow-purple-yellow-bg {
    background: rgb(60, 48, 119);
    background: url('../assets/images/wave-line.svg'), linear-gradient(90deg, rgba(60, 48, 119, 1) 50%, rgba(251, 236, 83, 1) 50%);
    height: 100vh;
}

/* Bg Purple Yellow Style End */

/* Book View Style Start */

.yow-book-shape {
    clip-path: polygon(1% 0%, 50% 3%, 99% 0%, 100% 100%, 0% 100%);
    background: #fff;
    padding: 50px 0px !important;
}

.container-book {
    width: 95%;
    margin: auto;
    position: relative;
}

.yow-contact-wrapper {
    padding: 25px 0px;
    position: relative;
}

/* Book View Style End */

/**Globel Style End**/


/** Components Style Start**/

/**Button Style Start**/
.yow-btn-yellow {
    font-size: 16px;
    font-weight: bold;
    background: var(--secondary-color);
    border-radius: 15px;
    padding: 10px 25px !important;
    color: var(--primary-color) !important;
    text-align: center;
    border: 1px solid var(--secondary-color);
    transition: 0.5s;
}

.yow-btn-yellow:hover {
    background: transparent;
    color: var(--secondary-color) !important;
}

.yow-btn-blue {
    font-size: 16px;
    font-weight: bold;
    background: var(--primary-color);
    border-radius: 15px;
    padding: 10px 25px !important;
    color: var(--secondary-color) !important;
    text-align: center;
    border: 1px solid var(--primary-color);
    transition: 0.5s;
}

.yow-btn-blue:hover {
    background: transparent;
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color);
}

.yow-btn-white {
    font-size: 16px;
    font-weight: bold;
    background: var(--primary-color);
    border-radius: 15px;
    padding: 10px 25px !important;
    color: var(--color-1) !important;
    text-align: center;
    border: 1px solid var(--primary-color);
    transition: 0.5s;
}

.yow-btn-white:hover {
    background: transparent;
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color);
}

.yow-btn-right {
    text-align: right;
    margin-left: auto;
    display: block;
}

.btn:focus {
    outline: 0;
    box-shadow: none;
}

/**Button Style End**/

/**Nav Style Start**/

.yow-nav-logo {
    width: 13%;
    margin-left: 33px;
}

.yow-nav-logo img {
    width: 100%;
}

.yow-nav-link {
    font-size: 16px;
    font-weight: bold;
    background: var(--secondary-color);
    border-radius: 15px;
    padding: 10px 25px !important;
    margin-right: 33px;
    color: var(--primary-color) !important;
    text-align: center;
    border: 1px solid var(--secondary-color);
    transition: 0.5s;
}

.yow-nav-bar {
    background-color: var(--primary-color);
    box-shadow: 0px 0px 10px;
}

.yow-nav-link:hover {
    background: transparent;
    color: var(--secondary-color) !important;
}

/**Nav Style End**/

/* Title Component start */

.title-wrapper {
    background-image: url('../assets/images/bg_title.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.title-wrapper h2 {
    color: var(--secondary-color);
    font-size: var(--font-size-22);
    width: 200px;
    margin-bottom: 0px;
}

/* Title Component end */

/* Drop Down Component Start */

.yow-drop-down {
    height: 80px;
    width: 80px;
    border-radius: 100%;
    background-image: linear-gradient(#604696, #FFFFFF);
    display: flex;
    justify-content: center;
    align-items: center;
}

.yow-drop-down img {
    width: 100%;
    margin: auto;
    object-fit: contain;
}

/* Drop Down Component End */

/* Tabs Vertical Component Style start */

.yow-wrapper {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    border-radius: 15px;
    text-align: center;
    margin-bottom: 15px;
}

.yow-heading {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border-radius: 15px;
}

/* Tabs Vertical Component Style End */

/* Tabs component style start */

.yow-tabs .nav {
    justify-content: center;
}

.yow-tabs {
    width: 100%;
    margin: auto;
}

.yow-first-tab {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.yow-last-tab {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.yow-tab-wrapper {
    width: 20%;
    border-right: 1px solid var(--white-color) !important;
    border-radius: 0;
}

.yow-tab-active {
    color: var(--primary-color) !important;
    background-color: var(--secondary-color) !important;
    border: 1px solid var(--secondary-color);
}

.yow-tab-active:hover,
.yow-tab-inactive:hover {
    color: var(--primary-color);
    background-color: transparent !important;
    border: 1px solid var(--primary-color);
    transition: 0.5s;
}

.yow-tab-inactive {
    color: var(--secondary-color);
    background-color: var(--primary-color) !important;
    border: 1px solid var(--primary-color);
}

/* Tab component style end */

/* tooltip style start */
.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
    cursor: pointer;
}

/* tooltip style end */

/* self component style start */
.yow-self-book {
    height: 150px;
    width: 150px;
}

.yow-self-book img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    transform: translate(0px, 0px);
}

.yow-self-book img:hover {
    transform: rotate(-20deg);
    transition: all 0.5s;
    cursor: pointer;
}

.yow-self {
    width: 85%;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5vh;
}

.yow-book-self-wrapper {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;
}

.yow-book-list {
    width: 70%;
    margin: auto;
    position: relative;
    z-index: 1;
}

.yow-book-card .card {
    width: 60%;
    margin: auto;
    cursor: default;
}

/* self component style end */

/** Components Style Start**/

/* Home Page Style Start */

/**Hero Banner Style Start **/

.logo img {
    text-align: center;
    margin: auto;
    display: block;
    width: 23%;
    margin-bottom: 30px;
}

.logo .caption {
    text-align: center;
    color: #fff;
    font-size: var(--font-size-28);
    line-height: var(--line-height-46);
    font-weight: var(--font-weight-500);
    margin-bottom: 30px;
}

.yow-bird-logo img {
    margin: auto;
    display: block;
    width: 25%;
    margin-bottom: 30px;
}


/**Hero Banner Style End **/

/* Home Page Style End */

/* Team Page Style Start */

.team .personal_info {
    font-size: var(--font-size-16);
    font-weight: var(--yow-semi-bold);
}

.team .title-wrapper {
    width: 50%;
    margin: auto;
}

.team .row {
    justify-content: center;
}

.team .yow-book-shape {
    padding: 50px 0px;
}

.personal_info img {
    width: 100%;
}

.team .image_size img {
    width: 100%;
}

.team p {
    margin: 0;
}

.team_image {
    height: 258px !important;
    object-fit: cover !important;
}

.linkedin {
    color: #0762C8;
}

/* Team Page Style End */

/* Request Demo Style Start */

.yow-request-title {
    font-size: 19px;
    font-weight: bold;
}

.whatsapp_icon {
    color: #075E54;
}

.insta {
    color: #FCAF45;
}

.yow-email a,
.yow-mobile a {
    color: var(--black-color);
    font-size: var(--font-size-16);
    text-decoration: none;
}

.yow-social-media {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 30px;
}

.yow-social-media-wrapper a svg {
    margin-left: 10px;
    cursor: pointer;
}

.yow-mobile {
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-600);
    text-align: center;
    margin-bottom: 30px;
}

.yow-contact-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: var(--font-size-22);
    font-weight: var(--font-weight-600);
}

.yow-email {
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-600);
    text-align: center;
    margin-bottom: 20px;
}

.yow-email a {
    color: var(--black-color);
    text-decoration: none;
}

.yow-form-title {
    text-align: center;
    margin: auto;
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-22);
    margin-bottom: 30px;
    width: 50%;
}

.yow-contact-subtitle {
    font-size: var(--font-size-20);
}

.form-wrapper {
    width: 70%;
    margin: auto;
}

.yow-apps {
    width: 50%;
    margin: auto;
}

.yow-apps img {
    width: 100%;
    object-fit: cover;
    margin-bottom: 30px;
}

.yow-responsive-devices {
    width: 50%;
    margin: auto;
}

.yow-responsive-devices img {
    width: 100%;
    object-fit: cover;
}

.yow-form-label {
    font-size: var(--font-size-18);
    font-weight: 600;
    color: var(--black-color);
    text-align: start;
    padding: 0px;
}

.yow-form-input {
    background-color: var(--gray-color);
    border: none;
    border-radius: 15px;
    padding: 10px;
}

/* Request Demo Style End */

/* School And Parent Pages Style Start */

/* School Tab 1 Style Start */

.school-content {
    padding-bottom: 20px;
    font-size: var(--font-size-18);
    font-weight: var(--yow-semi-bold);
    letter-spacing: 0.5px;
}

/* .school-title .title-wrapper {
    margin: 0 auto;
} */

.school-title-right .title-wrapper {
    margin-left: auto;
    margin-right: 0px;
}

.yow-drop-down {
    margin: auto;
}

/* School Tab 1 Style End */


/* School Tab 1 And Parent Tab 1 Style Start */

.parents-content {
    font-size: var(--font-size-18);
    font-weight: var(--yow-semi-bold);
    padding-top: 30px;
    padding-bottom: 80px;
    letter-spacing: 0.5px;
}

/* .parents-one .title-wrapper {
    width: 60%;
    margin-left: 50px;
} */

.parents-one .right_image {
    display: block;
    margin-left: auto;
    margin-right: 0px;
    padding: 30px 60px;
}

/* School Tab 3 And Parent Tab 1 Style End */

/* School And Parent Tab 2 Style Start */

.yow-cover-image {
    width: 90%;
}

.yow-cover-image img {
    width: 100%;
}

.yow-flip-arrow {
    width: 8%;
    position: absolute;
    bottom: 10%;
    right: 3%;
}

.yow-flip-arrow img {
    width: 100%;
}

/* School And Parent Tab 2 Style End */


/* School Tab 4 And Parent Tab 3 Style Start */

.yow-table-wrapper {
    width: 90%;
    border: 1px solid var(--black-color);
    border-radius: 15px;
}

/* School Tab 4 And Parent Tab 3 Style End */



/* Page Not Found Style & Loader Style Start */

.yow-page-wrapper {
    position: relative;
    height: 100vh;

}

.yow-page-wrapper .logo {
    margin-top: 10%;
}


.yow-laptop-image {
    position: absolute;
    left: 5%;
    top: 5%;
}

.yow-rocket-image {
    position: absolute;
    left: 5%;
    bottom: 8%;
}

.yow-notfound-text {
    text-align: center;
    color: var(--white-color);
    margin-bottom: 30px;
}

.yow-notfound-image {
    width: 40%;
    margin: auto;
}

.yow-notfound-image img {
    width: 100%;
    object-fit: cover;
}

.yow-footer {
    width: 80%;
    position: absolute;
    left: 5%;
    bottom: 8%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.yow-footer-nav {
    position: absolute;
    right: 5%;
    bottom: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.yow-footer-nav a {
    color: var(--white-color);

}

/* Page Not Found Style & Loader Style End */


/* School Tab 4 And Parent Tab 3 Style Start */
.yow-card {
    border: none;
}

.yow-subheading-wrapper {
    margin-top: 45px;
}

.yow-card-subheading-bg {
    position: relative;
    text-align: center;
    display: grid;
    width: 100%;
    height: 150px;
    place-items: center;
    background-image: url('../assets/images/yellow-bg.png');
    z-index: 1;
    background-size: contain;
    color: var(--primary-color);
    background-repeat: no-repeat;
    background-position: center;

}

.yow-card-subheading-bg h3 {
    font-size: 24px;
    margin-bottom: 0px;
}

.yow-card-subheading {
    display: flex;
    justify-content: start;
    align-items: center;
    position: relative;
}

.yow-card-subheading img {
    position: absolute;
    left: 80%;
}

.yow-card-top-space-1 {
    margin-top: 100px;
}


.yow-bg-purple-card {
    /* background-color: var(--color-1); */
    margin: 0px 10px;
    border-radius: 10px !important;
}

.yow-bg-yellow-card {
    background-color: var(--color-1);
    margin: 0px 10px;
    border-radius: 10px !important;
}

.yow-purple-card-header {
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 20px;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.yow-card-header-bg {
    position: relative;
    text-align: center;
    display: grid;
    width: 100%;
    height: 75px;
    place-items: center;
    background-image: url('../assets/images/bg_title.png');
    z-index: 1;
    background-size: contain;
    color: var(--white-color) ;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 15px;
}

.yow-card-header-bg h3 {
    display: flex;
    justify-self: center;
    align-items: center;
    font-size: 28px;
    padding: 0px;
    color: var(--white-color) ;
    margin: 0px;
}



.yow-bg-light-yellow-card,
.yow-bg-medium-yellow-card,
.yow-bg-yellow-card {
    background-color: var(--color-9);
    border-radius: 15px;
    height: 150px;
    width: 90%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.yow-bg-medium-yellow-card {
    background-color: var(--color-10) !important;
}


.yow-bg-yellow-card {
    background-color: var(--color-11) !important;
}

.yow-purple-border-bottom {
    border-bottom: 1px solid var(--color-12);
}

.yow-card-text {
    padding: 30px;
    text-align: center;
}

.yow-bg-yellow-card {
    background-color: var(--color-5);
    margin: 0px 0 15px;
    border-radius: 10px !important;
}

.yow-yellow-card-header {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    padding: 30px 20px;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.yow-yellow-border-bottom {
    border-bottom: 1px solid var(--color-4);
}

.yow-desktop-view {
    display: block;
}

.yow-mobile-view {
    display: none;
}

.yow-card-top-space {
    margin-top: 120px;
}

.yow-space-top {
    padding-top: 30px;
}

/* School Tab 4 And Parent Tab 3 Style End */

/* library Page Style Start */
img.yow-lib-book {
    cursor: pointer;
    /* width: 60%; */
    /* margin: auto; */
    /* display: block; */
}

img.yow-lib-book:hover {
    transform: rotate(-15deg);
}

img.yow-lib-book {
    transition: 0.5s;
}

.yow-library-bg {
    background: rgb(60, 48, 119);
    background: linear-gradient(90deg, rgba(60, 48, 119, 1) 30%, rgba(251, 236, 83, 1) 30%);
    width: 100%;
    height: 100vh;
}

.yow-library-left-content {
    color: var(--white-color);
}

.yow-library-heading {
    margin-top: 50px;
    font-size: var(--font-size-22);
}

.yow-library-logo {
    width: 200px;
    margin-top: 100px;
}

.yow-library-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.yow-library-content {
    width: 70%;
    font-size: var(--font-size-18);
    margin-top: 20px;
    margin-bottom: 100px;
    text-align: center;
}

/* library Page Style End */

/* subscription Page Style Start */

.subscribe h2 {
    color: white !important;
}

.yow-subscribe p {
    color: var(--primary-color);
    font-size: 22.5px;
}

.yow-subscribe label {
    color: var(--primary-color);
    font-size: 22.5px;
    font-weight: 500;
}

.yow-subscribe label p {
    font-size: 13px;
    color: var(--primary-color);
    letter-spacing: 2;
}

.subscribe-form-wrapper {
    width: 80%;
    margin-left: auto;

}

/* subscription Page Style End*/


/* next-icon page style start*/
.yow-next-icon img {
    width: 50px;
}

.yow-next-icon-library img {
    width: 40px;
}

.yow-next {
    position: absolute;
    bottom: 30px;

}

.yow-next-icon {
    position: relative;

}


.book-width-wrapper {
    position: relative;
    width: 95%;
    margin: auto;
}

.yow-next-icon-library {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: -45px;
}

/* next-icon page style end*/


/* Navbar page style start */

.nav-item {
    margin-left: -10px;
}

.yow-select {
    padding: 10px;
    border-radius: 15px !important;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    
    background-repeat: no-repeat, repeat;
    background-position: right 1rem center;
    border-radius: .25rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid #d8e2ef;
    color: #344040;
    background-size: 10px 12px;
    background-color: var(--gray-color);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    outline: none;
}

.home-margin{
    margin-top: 50px !important;
}
/* Navbar page style end */